<template>
  <MoleculesTitleCard heading="公式Facebook" color="facebook">
    <ClientOnly>
      <div ref="wrapperRef">
        <!--
          FIXME: 本来は「data-show-posts="true"」ではなく「data-tabs="timeline"」がFacebook推奨の設定となっているが、
                  その指定をするとFacebook側のバグでエラーが出てしまうため、いったん非推奨のほうで指定している。
                  もし今後、急に表示されなくなったりすることがあったら、ここを疑うのが良さそう。
          ref: https://github.com/armg/kiwi-nuxt/issues/690
        -->
        <div
          class="fb-page"
          data-href="https://www.facebook.com/dietplusjp"
          data-show-posts="true"
          data-width="298"
          data-height="260"
          data-small-header="false"
          data-adapt-container-width="true"
          data-hide-cover="false"
          data-show-facepile="true"
        >
          <blockquote
            cite="https://www.facebook.com/dietplusjp"
            class="fb-xfbml-parse-ignore"
          >
            <a href="https://www.facebook.com/dietplusjp"
              >DietPlus ダイエットプラス</a
            >
          </blockquote>
        </div>
      </div>
    </ClientOnly>
  </MoleculesTitleCard>
</template>

<script lang="ts" setup>
const wrapperRef = ref<HTMLDivElement>();

onMounted(async () => {
  // 後からウィジェットを発火させる
  // ref: https://developers.facebook.com/docs/javascript/reference/FB.init/v19.0
  await nextTick();
  if (window.FB) {
    window.FB.XFBML.parse(wrapperRef.value);
  }
});
</script>
