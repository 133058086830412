<template>
  <Transition>
    <div
      v-if="isShow"
      class="fixed w-screen h-screen bg-white top-0 left-0 z-50"
    >
      <h2 class="text-xl font-bold text-center py-3 border-b border-gray-300">
        <button
          class="text-gray-400 absolute top-2 left-0"
          data-testid="close-button"
          @click="close"
        >
          <ChevronLeftIcon :size="32" />
        </button>
        <span class="text-gray-500">検索</span>
      </h2>

      <div class="flex bg-gray-200 p-3">
        <button
          v-for="tab in tabs"
          :key="tab.key"
          :class="{
            'w-full first:border-r first:border-gray-350': true,
            'font-bold text-gray-500': activeTab === tab.key,
          }"
          date-testid="tab-button"
          @click="activeTab = tab.key"
        >
          {{ tab.text }}
        </button>
      </div>

      <div class="bg-gray-200 pb-6 px-3">
        <form
          class="px-5 py-3 rounded-xl border border-gray-350 bg-white flex items-center"
          @submit.prevent="submit"
        >
          <input
            v-model="keyword"
            type="text"
            :placeholder="placeholder"
            class="grow text-xl block"
            date-testid="input"
          />
          <button class="shrink-0">
            <img src="@/assets/img/header_search_circle.png" class="w-8" />
          </button>
        </form>
      </div>
    </div>
  </Transition>
</template>

<script setup lang="ts">
import ChevronLeftIcon from 'vue-material-design-icons/ChevronLeft.vue';

type TabKey = 'news' | 'recipe';

defineProps({
  isShow: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits<{
  (e: 'close'): void;
}>();

const keyword = ref('');
const activeTab = ref<TabKey>('news');

const tabs: {
  text: string;
  key: TabKey;
}[] = [
  {
    text: '記事',
    key: 'news',
  },
  {
    text: 'レシピ・メニュー',
    key: 'recipe',
  },
];

function close() {
  emit('close');
}

async function submit() {
  await navigateTo(
    {
      path: `/public/article/${activeTab.value}`,
      query: {
        s: keyword.value,
      },
    },
    {
      external: true,
    },
  );
}

const placeholder = computed(() => {
  switch (activeTab.value) {
    case 'recipe':
      return '料理名・食材';
    default:
      return 'キーワード';
  }
});
</script>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: transform 0.4s ease;
}

.v-enter-from,
.v-leave-to {
  transform: translateX(100%);
}
</style>
