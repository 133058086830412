<template>
  <Transition>
    <div
      v-if="isShow"
      class="fixed w-screen h-screen bg-white top-0 left-0 z-50"
    >
      <h2 class="text-xl font-bold text-center py-3 border-b border-gray-300">
        <span class="text-gray-500">メニュー</span>
        <button
          class="text-gray-400 absolute top-2 right-0"
          data-testid="close-button"
          @click="close"
        >
          <ChevronRightIcon :size="32" />
        </button>
      </h2>

      <div v-for="item in items" :key="item.text">
        <h3 class="bg-gray-200 p-2 font-bold text-gray-500">{{ item.text }}</h3>
        <!--
          NOTE: ページ遷移に違和感が出ないように、externalはtrueで固定する
          ref: https://github.com/armg/kiwi-nuxt/pull/632
        -->
        <NuxtLink
          v-for="secondaryItem in item.items"
          :key="secondaryItem.text"
          :to="secondaryItem.to"
          :external="true"
          :target="secondaryItem.newWindow ? '_blank' : undefined"
          :rel="secondaryItem.newWindow ? 'noopener' : undefined"
          class="block px-2 py-5 border-b border-gray-200"
        >
          {{ secondaryItem.text }}
        </NuxtLink>
      </div>
    </div>
  </Transition>
</template>

<script setup lang="ts">
import ChevronRightIcon from 'vue-material-design-icons/ChevronRight.vue';

defineProps({
  isShow: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits<{
  (e: 'close'): void;
}>();

type Item = {
  text: string;
  items: {
    text: string;
    to: string;
    external?: boolean;
    newWindow?: boolean;
  }[];
};

const items: Item[] = [
  {
    text: '食事改善アプリのご紹介',
    items: [
      {
        text: '好きなものを我慢しないダイエットアプリ',
        to: '/s/service_dietplus',
        newWindow: true,
      },
    ],
  },
  {
    text: 'おすすめサービス',
    items: [
      {
        text: 'ニュース',
        to: '/public/article/news',
      },
      {
        text: 'ダイエット中の疑問',
        to: '/public/article/dietqa',
      },
      {
        text: 'ダイエットレシピ',
        to: '/public/article/recipe/top',
      },
    ],
  },
];

function close() {
  emit('close');
}
</script>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: transform 0.4s ease;
}

.v-enter-from,
.v-leave-to {
  transform: translateX(-100%);
}
</style>
