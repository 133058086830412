<template>
  <header
    class="max-w-content-width mx-auto py-1 justify-between items-center md:flex hidden"
  >
    <div class="shrink-0">
      <NuxtLink to="/"><AtomsDietPlusLogo class="w-[250px] mr-20" /></NuxtLink>
    </div>
    <ul class="flex justify-around grow pt-2">
      <li v-for="nav in navMain" :key="nav.to" class="py-1">
        <NuxtLink
          :to="nav.to"
          :external="nav.external"
          class="text-gray-400 hover:text-gray-500 hover:underline underline-offset-4 decoration-2"
          :target="nav.newWindow ? '_blank' : undefined"
          :rel="nav.newWindow ? 'noopener' : undefined"
          >{{ nav.text }}</NuxtLink
        >
      </li>
      <li
        v-for="(nav, i) in navSub"
        :key="nav.to"
        :class="`border-l border-gray-400 py-1 ${i === 0 && 'pl-16'}`"
      >
        <NuxtLink
          :to="nav.to"
          :external="nav.external"
          class="text-gray-400 hover:text-gray-500 hover:underline underline-offset-4 decoration-2"
          :target="nav.newWindow ? '_blank' : undefined"
          :rel="nav.newWindow ? 'noopener' : undefined"
          >{{ nav.text }}</NuxtLink
        >
      </li>
    </ul>
  </header>
</template>

<script setup lang="ts">
type Nav = {
  text: string;
  to: string;
  external?: boolean;
  newWindow?: boolean;
};

const navMain: Nav[] = [
  {
    text: 'トップ',
    to: '/',
  },
  {
    text: 'ニュース',
    to: '/public/article/news',
  },
  {
    text: 'ダイエットレシピ',
    to: '/public/article/recipe/top',
    external: true,
  },
];

const navSub: Nav[] = [
  {
    text: 'Dietアプリ',
    to: '/s/service_dietplus',
    external: true,
    newWindow: true,
  },
];
</script>
