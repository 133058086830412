<template>
  <ClientOnly>
    <div ref="wrapperRef">
      <div
        class="fb-like"
        data-href="https://www.facebook.com/dietplusjp"
        data-layout="button_count"
        data-action="like"
        data-show-faces="true"
        data-share="false"
      />
    </div>
  </ClientOnly>
</template>

<script lang="ts" setup>
const wrapperRef = ref<HTMLDivElement>();

onMounted(async () => {
  // 後からウィジェットを発火させる
  // ref: https://developers.facebook.com/docs/javascript/reference/FB.init/v19.0
  await nextTick();
  if (window.FB) {
    window.FB.XFBML.parse(wrapperRef.value);
  }
});
</script>
