<template>
  <MoleculesTitleCard heading="公式X" color="x">
    <ClientOnly>
      <!-- NOTE: hrefをx.comに変えてしまうと動かなくなるので注意 -->
      <a
        ref="widget"
        class="twitter-timeline"
        data-lang="ja"
        data-height="250"
        href="https://twitter.com/DietPlusjp?ref_src=twsrc%5Etfw"
        >ダイエットプラスのポスト</a
      >
    </ClientOnly>
  </MoleculesTitleCard>
</template>

<script setup lang="ts">
const widget = ref<HTMLElement>();

onMounted(async () => {
  // ref: https://developer.twitter.com/en/docs/twitter-for-websites/javascript-api/guides/scripting-loading-and-initialization
  await nextTick();
  if (window.twttr) {
    window.twttr.widgets.load(widget.value);
  }
});
</script>
