<script setup lang="ts">
import { useBreadcrumbs, type BreadCrumb } from '@/composables/useBreadcrumbs';

const route = useRoute();
const isShow = computed(() => breadcrumbsState.value.length);

const currentPath = route.fullPath;

const { breadcrumbsState } = useBreadcrumbs();

const breadcrumbs = computed<BreadCrumb[]>(() => {
  return [
    {
      to: '/',
      text: 'トップ',
    },
    ...(breadcrumbsState.value ?? []),
  ].map((breadcrumbs) => ({
    ...breadcrumbs,
    to: breadcrumbs.to ?? currentPath,
  }));
});

function isLast(i: number) {
  return i === breadcrumbs.value.length - 1;
}
</script>

<template>
  <div
    v-if="isShow"
    class="text-sm pt-3 pb-6 md:pb-3 flex flex-wrap px-2 md:px-0"
  >
    <div v-for="(breadcrumb, i) in breadcrumbs" :key="i" class="flex">
      <NuxtLink :to="breadcrumb.to" class="hover:underline">
        {{ breadcrumb.text }}
      </NuxtLink>
      <span v-if="!isLast(i)" class="mx-3 text-gray-350">&gt;</span>
    </div>
  </div>
</template>
