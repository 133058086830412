<template>
  <MoleculesTitleCard heading="ダイエットプラス通信">
    <div class="flex items-center p-3">
      <img
        class="shrink-0 rounded mr-2"
        src="~/assets/img/image_mail-magazine.png"
      />
      <span class="grow"
        >17万人が読んでいるダイエットメールマガジン。お得なキャンペーン情報もメールマガジン会員様だけにお送りしています。</span
      >
    </div>

    <div class="text-center pb-4">
      <AtomsDietPlusButton to="/public/magazineSubscribe"
        >メールマガジンに登録</AtomsDietPlusButton
      >
    </div>
  </MoleculesTitleCard>
</template>
