<template>
  <ClientOnly>
    <div v-if="isDisplayable">
      <div id="logly-lift-4283232"></div>
      <div ref="insertTargetRef" />
    </div>
  </ClientOnly>
</template>

<script setup lang="ts">
const { isFromNavel, isFromSp } = useUserAgentState();
const insertTargetRef = ref<HTMLDivElement>();

const isDisplayable = computed(() => {
  return !isFromNavel.value && isFromSp.value;
});

onMounted(async () => {
  await nextTick();
  if (isDisplayable.value) {
    const lgylyLiftWidget = document.createElement('script');
    lgylyLiftWidget.type = 'text/javascript';
    lgylyLiftWidget.async = true;
    lgylyLiftWidget.src =
      ('https:' == document.location.protocol ? 'https://' : 'http://') +
      'l.logly.co.jp/lift_widget.js?adspot_id=4283232';
    insertTargetRef.value?.appendChild(lgylyLiftWidget);
  }
});
</script>
