<template>
  <div
    v-if="shouldDisplayAppBanner"
    class="flex items-center bg-gray-200 px-4 py-1"
    data-testid="wrapper"
  >
    <button
      data-testid="close-button"
      class="shrink-0 mr-2 flex items-center"
      @click="close"
    >
      <CloseIcon class="text-gray-500" :size="14" />
    </button>

    <NuxtLink
      to="/s/service_dietplus"
      target="_blank"
      rel="noopener"
      class="flex grow items-center"
    >
      <img src="~/assets/img/icon_app.png" class="w-[39px] mr-4 shrink-0" />
      <p class="text-xs text-gray-500 grow">
        やせる食べ方、見つけませんか？<br />まずは無料で24時間体験！
      </p>
      <p
        class="text-xs rounded-xl py-1 px-3 border-gray-500 border font-bold shrink-0"
      >
        詳細はこちら
      </p>
    </NuxtLink>
  </div>
</template>

<script setup lang="ts">
import CloseIcon from 'vue-material-design-icons/Close.vue';
import { useAppBannerStore } from '~/store/appBannerStore';

const appBannerStore = useAppBannerStore();

const shouldDisplayAppBanner = computed(
  () => appBannerStore.shouldDisplayAppBanner,
);

function close() {
  appBannerStore.closeAppBanner();
}
</script>
