<script setup lang="ts">
const { initialize, refresh } = useGooglePublisherTag();

const route = useRoute();
const layoutCustomProps = useAttrs();

const showSideBar = computed(() => {
  return !route.meta.hideSideBar && layoutCustomProps['hide-side-bar'] !== true;
});

const mainBackground = computed(() => {
  return route.meta.contentBg || '';
});

onBeforeMount(() => {
  initialize();
});

const { screenSize } = useScreenSize();

watch(screenSize, (_, oldVal) => {
  if (oldVal) {
    refresh();
  }
});
</script>

<template>
  <div>
    <MoleculesDietPlusPcHeader class="mb-2" />
    <MoleculesDietPlusSpHeader />

    <div
      data-testid="content-wrapper"
      :style="{
        backgroundColor: mainBackground,
      }"
    >
      <div class="md:flex max-w-content-width mx-auto justify-between">
        <div class="grow overflow-hidden">
          <MoleculesBreadcrumbs />

          <main class="bg-white">
            <slot />
          </main>
        </div>

        <OrganismsDietPlusSideBar
          v-if="showSideBar"
          class="hidden md:block md:w-[300px] ml-6 shrink-0"
        />
      </div>
    </div>

    <MoleculesDietPlusFooter />
    <MoleculesLoglyLift />
  </div>
</template>
