<template>
  <header class="md:hidden">
    <MoleculesAppBanner />
    <div class="py-1 flex justify-between items-center">
      <button
        class="shrink-0 p-2 h-[44px]"
        date-testid="open-menu-button"
        @click="openMenu"
      >
        <img src="~/assets/img/header_toggle.png" class="h-full" />
      </button>
      <div class="shrink-0 h-full">
        <NuxtLink to="/"><AtomsDietPlusLogo class="w-[100px]" /></NuxtLink>
      </div>
      <button
        class="shrink-0 p-2 h-[44px]"
        date-testid="open-search-button"
        @click="openSearch"
      >
        <img src="~/assets/img/header_search.png" class="h-full" />
      </button>
    </div>
    <div class="flex grow border-b border-gray-300">
      <NuxtLink
        v-for="nav in navs"
        :key="nav.to"
        :to="nav.to"
        :external="nav.external"
        class="text-gray-500 py-4 w-full text-center"
        :target="nav.newWindow ? '_blank' : undefined"
        :rel="nav.newWindow ? 'noopener' : undefined"
        >{{ nav.text }}</NuxtLink
      >
    </div>

    <MoleculesDietPlusSpHeaderMenu :is-show="isShowSpMenu" @close="closeMenu" />

    <MoleculesDietPlusSpHeaderSearch
      :is-show="isShowSpSearch"
      @close="closeSearch"
    />
  </header>
</template>

<script setup lang="ts">
type Nav = {
  text: string;
  to: string;
  external?: boolean;
  newWindow?: boolean;
};

const navs: Nav[] = [
  {
    text: 'ニュース',
    to: '/public/article/news',
  },
  {
    text: 'レシピ',
    to: '/public/article/recipe/top',
    external: true,
  },
];

const isShowSpMenu = ref(false);
const isShowSpSearch = ref(false);

function openMenu() {
  isShowSpMenu.value = true;
}

function closeMenu() {
  isShowSpMenu.value = false;
}

function openSearch() {
  isShowSpSearch.value = true;
}

function closeSearch() {
  isShowSpSearch.value = false;
}
</script>
