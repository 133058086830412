<template>
  <aside>
    <NuxtLink
      to="/s/service_dietplus"
      target="_blank"
      rel="noopener"
      class="hover:opacity-40 duration-300 block mb-5"
      ><img src="@/assets/img/dpapp_banner.png"
    /></NuxtLink>

    <MoleculesNewsPickUp :items="pickupItems" class="mb-5" />

    <MoleculesGoogleAd
      id="div-gpt-ad-1534157071878-0"
      target="pc"
      class="mb-5 w-[300px] h-[250px]"
    />

    <MoleculesMagazineIntroduction class="mb-5" />

    <MoleculesFacebookWidget class="mb-5" />
    <MoleculesXWidget class="mb-5" />

    <MoleculesGoogleAd
      id="div-gpt-ad-1534157096547-0"
      target="pc"
      class="w-[300px] h-[250px]"
    />
  </aside>
</template>

<script setup lang="ts">
const pickupItemsResponse = await useFetch('/api/kiwi_user/articles/pickup');
const pickupItems = pickupItemsResponse.data.value ?? [];
</script>
