import { addDays } from 'date-fns';
import { defineStore } from 'pinia';

export const useAppBannerStore = defineStore('appBanner', {
  actions: {
    closeAppBanner() {
      const appBannerClosed = useCookie('appBannerClosed', {
        expires: addDays(new Date(), 1),
      });
      appBannerClosed.value = 'true';
    },
  },
  getters: {
    shouldDisplayAppBanner() {
      const { isFromNavel } = useUserAgentState();
      const appBannerClosed = useCookie('appBannerClosed');
      return !isFromNavel.value && !appBannerClosed.value;
    },
  },
});
