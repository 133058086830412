<template>
  <footer class="max-w-content-width px-4 pt-0 md:pt-32 pb-32 md:px-0 mx-auto">
    <div class="hidden md:flex text-sm pb-10">
      <div v-for="item in primaryNav" :key="item.text" class="w-1/4">
        <p class="font-bold text-red-300 mb-4">{{ item.text }}</p>
        <p v-for="subItem in item.items" :key="subItem.text" class="mb-2">
          <NuxtLink
            :to="subItem.to"
            :external="subItem.external"
            class="underline"
            :target="subItem.newWindow ? '_blank' : null"
            :rel="subItem.newWindow ? 'noopener' : null"
            >{{ subItem.text }}</NuxtLink
          >
        </p>
      </div>
    </div>

    <div class="md:flex gap-8 mb-16 mb:mb-32">
      <div class="border-t pt-8 border-gray-350 grow text-sm">
        <div v-for="item in secondaryNav" :key="item.text" class="flex">
          <p class="font-bold mr-10 mb-5 shrink-0">{{ item.text }}</p>
          <div class="flex gap-4">
            <NuxtLink
              v-for="subItem in item.items"
              :key="subItem.text"
              :to="subItem.to"
              :external="subItem.external"
              class="underline"
              :target="subItem.newWindow ? '_blank' : null"
              :rel="subItem.newWindow ? 'noopener' : null"
              >{{ subItem.text }}</NuxtLink
            >
          </div>
        </div>
      </div>
      <div
        class="shrink-0 text-center rounded-lg overflow-hidden border border-gray-350 pb-4"
      >
        <p class="mb-2 border-t-4 border-red-400 pt-4 px-16">
          ダイエットプラスに問い合わせ
        </p>
        <AtomsDietPlusButton :to="CONTACT_URL" :new-window="true"
          >お問い合わせ</AtomsDietPlusButton
        >
      </div>
    </div>

    <div class="flex gap-6 mb-4">
      <AtomsDietPlusLogo class="w-28" />
      <img src="~/assets/img/privacy.gif" class="w-8 h-8" />

      <XShareButton />
      <AtomsFacebookLikeButton />
    </div>

    <small class="text-sm"
      >株式会社フィッツプラス &copy;FitsPlus Inc. All Rights Reserved.</small
    >
  </footer>
</template>

<script setup lang="ts">
import XShareButton from '@shared/components/atoms/XShareButton.vue';
import { CONTACT_URL } from '~/constants/contactUrl';

type Nav = {
  text: string;
  items: {
    text: string;
    to: string;
    external?: boolean;
    newWindow?: boolean;
  }[];
};

const primaryNav: Nav[] = [
  {
    text: 'ダイエットニュース・特集',
    items: [
      {
        text: 'ニュース',
        to: '/public/article/news',
      },
      {
        text: 'ダイエット中の疑問',
        to: '/public/article/dietqa',
      },
    ],
  },
  {
    text: 'レシピ',
    items: [
      {
        text: 'ダイエットレシピ',
        to: '/public/article/recipe/top',
        external: true,
      },
    ],
  },
  {
    text: 'スマートフォンアプリの紹介',
    items: [
      {
        text: 'DietPlus Pro',
        to: '/s/service_dietplus',
      },
    ],
  },
];

const secondaryNav: Nav[] = [
  {
    text: 'サポート',
    items: [
      {
        text: 'サイトのご利用にあたって',
        to: '/s/notice_of_recipe',
      },
      {
        text: 'メールマガジン購読解除',
        to: '/public/magazineUnsubscribe',
      },
    ],
  },
  {
    text: '企業情報',
    items: [
      {
        text: '運営会社',
        to: 'https://fitsplus.co.jp/',
        newWindow: true,
      },
      {
        text: '採用について',
        to: 'https://fitsplus.co.jp/recruit',
        newWindow: true,
      },
      {
        text: '個人情報保護方針',
        to: 'https://fitsplus.co.jp/privacy',
        newWindow: true,
      },
    ],
  },
];
</script>
